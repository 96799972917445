import React from 'react'
import '../../Style.css';
import '../../Media-styles.css';
import Header from '../../Header';
import Footer from '../../Footer';
import '../Styles/PoolGrid.css';
import Drawing3D from '../../img/3d/Elysian.png'
import Slider from './Slider';
import PoolFeatures from '../../../components/Pool-Series/Istana_Terazza_Elysian/Pool-Features';
import PoolDescription from '../../../components/Pool-Series/Istana_Terazza_Elysian/Pool-Description';
import RangeFlyer from '../../Pool-Series/Istana_Terazza_Elysian/Range-Flyer';
import { Helmet } from 'react-helmet';

function Elysian() {
    return (
        <>
                
<Helmet>
                <title>Elysian - Villa Series - Aqua Technics Canada - Fibreglass Swimming Pools</title>
                <meta name="title" content="Aqua Technics Canada - Elysian - Villa Series - Fibreglass Swimming Pools" />
                <meta name="description" content="Elysian - Villa Series - Aqua Technics Canada - Fibreglass Swimming Pools" />
                <meta name="keywords" content="Elysian - Villa Series - Aqua Technics Canada - Fibreglass Swimming Pools - Pool Price" />
                <meta property="og:title" content="About og title" />
                <meta property="og:description" content="og-description" />
            </Helmet>
            <Header />

            <div className='inner_container'>


                <div className='inner-body-container'>
                    <div className='inner_body'>
                        <section className="section-container">

                            <div className="section-body">
                                <div className="section-left">
                                    <h2 className="pool-series">
                                        Villa Series
                                    </h2>
                                    <Slider />


                                </div>

                                <div className="section-right">
                                    <img src={Drawing3D} alt="Infinity" />
                                   <RangeFlyer />
                                    <h2 className="pool-feature">
                                        Villa Series Features
                                    </h2>


                                    <PoolFeatures />

                                </div>
                            </div>
                            <PoolDescription />
                        </section>

                    </div>

                </div>
            </div>

            <Footer />
        </>
    )
}

export default Elysian;

import React from 'react'
import GrapheneBanner from './img/graphene-ban_home.webp'
/*import BestSellers from './Best-Sellers';*/
import WelcomeBan from './img/gallery-8.webp';
import GallerySection from './Home-Gallery-Section';
import MpdBanner from './img/Modules_MPD.webp';
import GNT from '../components/Home-Carousel/Carousel';
function Welcome() {
  return (
    <>
      <div className='welcome-container sml-src-container'>
        <div className='welcome-col-left'><div className='welcome-copy'><h1>World Class Swimming Pools</h1>

          <h3>Aqua Technics Pools manufacture the most advanced and highest quality fibreglass composite pools in the world.</h3><h3>Our technologically advanced swimming pool range is designed for unparalleled strength with superior colour retention, ensuring long-lasting colour vibrancy.</h3></div></div>



        <div className='welcome-col-right world-class'><img src={WelcomeBan} alt="welcome" /></div>


      </div>

      <GallerySection />
<div className='desk-view'>
      <div className='welcome-container-gnt'>
        <div className='welcome-col-left'><div className='welcome-copy'><h1>A New Generation of Swimming Pools</h1>

          <h3>Designed and engineered like no other, Graphene Nano-Tech® is a revolutionary process infusing graphene nano technology in every Aqua Technics pool…</h3></div>
          <div className='line-btn'><a href="/Quality">Find out more</a></div></div>


        <div className='welcome-col-right'><img src={GrapheneBanner} alt="welcome" /><div className='gnt-ban'><GNT /></div></div>


      </div>


      <div className='welcome-container-mpd  mpd-section-desk'>
        <div className='mpd-col-left'><img src={MpdBanner} alt="welcome" /></div>


        <div className='mpd-col-right'><div className='mpd-copy'><h1>Visualise your dream pool instantly!</h1>

          <h3>You don’t need to leave home to discover the perfect pool design for your outdoor space…</h3></div>
          <div className='mpd-btn'><a href="https://mypooldesign.com.au/" target="_blank" rel="noreferrer">Download Today</a></div></div>


      </div>
      </div>

      <div className='mob-view'>

      <div className='welcome-container-gnt'>
        <div className='welcome-col-left'><div className='welcome-copy'><h1>A New Generation of Swimming Pools</h1>

          <h3>Designed and engineered like no other, Graphene Nano-Tech® is a revolutionary process infusing graphene nano technology in every Aqua Technics pool…</h3></div>
          <div className='line-btn'><a href="/Quality">Find out more</a></div></div>


        <div className='welcome-col-right'><img src={GrapheneBanner} alt="welcome" /><div className='gnt-ban'><GNT /></div></div>


      </div>

      <div className='welcome-container-gnt'>
        <div className='welcome-col-left'><div className='welcome-copy'><h1>Visualise your dream pool instantly!</h1>

          <h3>You don’t need to leave home to discover the perfect pool design for your outdoor space…</h3></div>
          <div className='line-btn'><a href="https://mypooldesign.com.au/" target="_blank" rel="noreferrer">Download Today</a></div></div>


        <div className='welcome-col-right'><img src={MpdBanner} alt="welcome" /></div>


      </div>

      </div>

     
      
  
    </>
  )
}

export default Welcome


import "glider-js/glider.min.css";
import React from "react";
import PhotosCarousel from "../../Slider/PhotosCarousel";

const photosToShow = [
  "https://aquatechnicspools.ca/img/sovereign-1.webp",



  
];

const thumbnailsCarouselConfig = {
  itemWidth: 30,
  slidesToShow: "auto",
  slidesToScroll: "auto"
};

export default function App() {
  // const thumbnailsGliderRef = React.useRef(null);
  const [currentPhotoIdx, setCurrentPhotoIdx] = React.useState(0);
  const onClickPhotoHandler = React.useCallback(
    (idx) => () => setCurrentPhotoIdx(idx),
    []
  );
  const onSlideChangeHandler = React.useCallback(
    (photoIdx) => {
      setCurrentPhotoIdx(photoIdx);
    },
    [setCurrentPhotoIdx]
  );
  const photos = React.useMemo(
    () =>
      photosToShow.map((photoUrl, idx) => {
        const key = `${photoUrl}_${idx}`;
        let className = "slide__content";
        if (currentPhotoIdx === idx) {
          className += " --current-selected";
        }
        return (
          <div
            key={key}
            className={className}
            onClick={onClickPhotoHandler(idx)}
          >
            <img alt="AT Canada" src={photoUrl} />
          </div>
        );
      }),
    [onClickPhotoHandler, currentPhotoIdx]
  );

  return (
    <div className="App">
      <div className="photos-gallery">
        <PhotosCarousel
          className="photo-wrapper"
          onSlideChange={onSlideChangeHandler}
          currentSlideIdx={currentPhotoIdx}
        >
          {photos}
        </PhotosCarousel>

        <div className="thumb-container">
        <PhotosCarousel
          className="thumbnails-wrapper"
          config={thumbnailsCarouselConfig}
          currentSlideIdx={currentPhotoIdx}
        >
          {photos}
        </PhotosCarousel></div>
      </div>
    </div>
  );
}
